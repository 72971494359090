let LeaderLine = require('leader-line');
var intervals= [];
var lines = []

document.addEventListener('turbolinks:load', () => {
    if (document.getElementById('bullets-section')) {
        let existing_lines = $('.leader-line')
        existing_lines.each((line) => { line.remove()})
        setup_variables();
        drawLines(line_blueprints)
        intervals.push(LineLoop())
        intervals.push(LineLoop())
        intervals.push(LineLoop(2000))
    }
});

document.addEventListener('turbolinks:before-render', () => {
    intervals.forEach(interval => {
        clearInterval(interval)
    });  
    intervals = [];
    lines.forEach(line => {
        line.remove();
    });
    lines = []
})

function setup_variables() {
    LeaderLine.positionByWindowResize = false;
    primary = '#0098d0';
    dark = '#00235f';
    light = '#aae4e7';
    secondary = '#235ccf';
    warning = '#ff9321';
    info = '#61d1cf';
    letterArray = [...'abcdefghijklmnopqr']

    bulletLibrary = letterArray.reduce((letter, bullet) => ({
            ...letter, [bullet]: document.getElementById(`bullet-${bullet}`)
        }), {}
    )


    // [StartElement, EndElement, HoverElement, LineColor]
    line_blueprints = [
        [bulletLibrary['c'], bulletLibrary['d'], bulletLibrary['c'], primary],
        [bulletLibrary['c'], bulletLibrary['e'], bulletLibrary['c'], primary],
        [bulletLibrary['f'], bulletLibrary['c'], bulletLibrary['c'], secondary],
        [bulletLibrary['c'], bulletLibrary['g'], bulletLibrary['c'], primary],
        [bulletLibrary['g'], bulletLibrary['h'], bulletLibrary['c'], primary],
        [bulletLibrary['b'], bulletLibrary['c'], bulletLibrary['c'], secondary],
        [bulletLibrary['b'], bulletLibrary['a'], bulletLibrary['c'], primary],
        [bulletLibrary['b'], bulletLibrary['e'], bulletLibrary['c'], secondary],
        [bulletLibrary['j'], bulletLibrary['e'], bulletLibrary['c'], secondary],

        [bulletLibrary['b'], bulletLibrary['e'], bulletLibrary['k'], secondary],
        [bulletLibrary['b'], bulletLibrary['a'], bulletLibrary['k'], secondary],
        [bulletLibrary['j'], bulletLibrary['e'], bulletLibrary['k'], secondary],
        [bulletLibrary['j'], bulletLibrary['k'], bulletLibrary['k'], secondary],
        [bulletLibrary['n'], bulletLibrary['k'], bulletLibrary['k'], secondary],
        [bulletLibrary['k'], bulletLibrary['l'], bulletLibrary['k'], primary],
        [bulletLibrary['l'], bulletLibrary['m'], bulletLibrary['k'], primary],
        [bulletLibrary['n'], bulletLibrary['o'], bulletLibrary['k'], secondary],
        [bulletLibrary['k'], bulletLibrary['o'], bulletLibrary['k'], primary],
        [bulletLibrary['o'], bulletLibrary['p'], bulletLibrary['k'], secondary],

        [bulletLibrary['n'], bulletLibrary['o'], bulletLibrary['q'], secondary],
        [bulletLibrary['n'], bulletLibrary['q'], bulletLibrary['q'], secondary],
        [bulletLibrary['p'], bulletLibrary['o'], bulletLibrary['q'], primary],
        [bulletLibrary['q'], bulletLibrary['o'], bulletLibrary['q'], primary],
        [bulletLibrary['q'], bulletLibrary['r'], bulletLibrary['q'], secondary],
        [bulletLibrary['i'], bulletLibrary['r'], bulletLibrary['q'], primary],
        [bulletLibrary['h'], bulletLibrary['i'], bulletLibrary['q'], primary],
        [bulletLibrary['h'], bulletLibrary['g'], bulletLibrary['q'], primary],
    ]
    lines = []
}

function addLine(startElement, endElement, hoverElement, color) {
    let originalColor = hoverElement.style.backgroundColor
    var line = new LeaderLine(
        LeaderLine.pointAnchor(startElement),
        LeaderLine.pointAnchor(endElement), {
            hide: true,
            path: 'straight',
            endPlug: 'behind',
            color: color,
            size: 2,
        }
    );
    lines.push(line);
    hoverElement.addEventListener('mouseover', () => {
        handleMouseOver(line, hoverElement)
    });
    hoverElement.addEventListener('mouseout', () => {
        handleMouseOut(line, hoverElement, originalColor)
    });
}

function handleMouseOver(line, hoverElement) {
    line.show('draw');
    hoverElement.style.backgroundColor = dark;
}

function handleMouseOut(line, hoverElement, originalColor) {
    line.hide('draw');
    hoverElement.style.backgroundColor = originalColor;
}

function drawLine(startPoint, interval,  sequenced) {
    endPoint = sequenced ? nextDynamicLineTarget(startPoint) : bulletLibrary[letterArray.random()]
    let NextLine = new LeaderLine(
        LeaderLine.pointAnchor(startPoint),
        LeaderLine.pointAnchor(endPoint), {
            hide: true,
            path: 'straight',
            endPlug: 'behind',
            color: [primary, secondary].random(),
            size: 3,
            animOptions: {
                duration: interval/2,
                timing: 'ease-in-out'
            }
        });
    NextLine.show('draw')
    setTimeout(() =>     NextLine.hide('fade'), interval/2)
    setTimeout(() =>     NextLine.remove(), interval)
}

function LineLoop(interval = 1000, sequenced = true) {
    return window.setInterval(() => {
        drawLine(bulletLibrary[letterArray.random()], interval, sequenced)
    }, interval)
}

function nextDynamicLineTarget(startPoint) {
    let previousLetter = objectKeyByValue(bulletLibrary, startPoint)
    nextBubble = Math.random() < .5 ? bulletLibrary[letterArray.next(previousLetter)] : bulletLibrary[letterArray.previous(previousLetter)]
    return nextBubble

}

function objectKeyByValue(obj, val) {
    return Object.entries(obj).find(i => i[1] === val)[0];
}

Array.prototype.random = function () {
    return this[Math.floor((Math.random() * this.length))];
}

Array.prototype.next = function (entry) {
    index = this.indexOf(entry)
    if (index == this.length - 1) {
        return this[0]
    } else {
        return this[index + 1]
    }
}

Array.prototype.previous = function (entry) {
    index = this.indexOf(entry)
    if (index == 0) {
        return this[this.length-1]
    } else {
        return this[index - 1]
    }
}

function drawLines(line_blueprints) {
    line_blueprints.forEach(line => {
        addLine(line[0], line[1], line[2], line[3])
    });
}

window.addEventListener('resize', () => {
    if (typeof lines !== 'undefined') {
        setTimeout(() => {
            lines.forEach(line => {
                line.position()
            });
        }, 300);
    }
})
